import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import React, { Dispatch } from 'react';
import { BillingPermissionData } from '../types';

// State
type AuthState = {
  user: CognitoUser | any;
  session: CognitoUserSession | undefined;
  isChecking: boolean;
  gettingPermissions: boolean;
  billingPermissions: BillingPermissionData[];
  tokenError: boolean;
  authorizeError:
    | 'none'
    | 'default'
    | 'pending'
    | 'inactive'
    | 'unauthorized'
    | 'terminated';
  status: { HasSignedICA: boolean; NPNVerified: boolean } | null;
};
const initialState: AuthState = {
  user: undefined,
  session: undefined,
  isChecking: true,
  gettingPermissions: false,
  billingPermissions: [],
  tokenError: false,
  authorizeError: 'none',
  status: null,
};

// Actions
type AuthActions =
  | { type: 'USER_CLEAR' }
  | { type: 'TOKEN_ERROR' }
  | { type: 'USER_SET'; payload: CognitoUser }
  | { type: 'SESSION_SET'; payload: CognitoUserSession }
  | { type: 'SET_CHECKING'; payload: boolean }
  | { type: 'AUTHORIZE_ERROR'; payload: AuthState['authorizeError'] }
  | { type: 'SET_BILLING_GET'; payload: boolean }
  | { type: 'SET_BILLING_PERM'; payload: BillingPermissionData[] }
  | {
      type: 'SET_STATUS';
      payload: { HasSignedICA: boolean; NPNVerified: boolean } | null;
    };

const reducer = (state: AuthState, action: AuthActions) => {
  switch (action.type) {
    case 'USER_CLEAR': {
      return { ...state, user: undefined, session: undefined };
    }
    case 'TOKEN_ERROR': {
      return { ...state, tokenError: true };
    }
    case 'AUTHORIZE_ERROR': {
      return { ...state, authorizeError: action.payload };
    }
    case 'USER_SET': {
      return { ...state, user: action.payload };
    }
    case 'SESSION_SET': {
      return { ...state, session: action.payload };
    }
    case 'SET_CHECKING': {
      return { ...state, isChecking: action.payload };
    }
    case 'SET_BILLING_GET': {
      return { ...state, gettingPermissions: action.payload };
    }
    case 'SET_BILLING_PERM': {
      return {
        ...state,
        gettingPermissions: false,
        billingPermissions: action.payload,
      };
    }
    case 'SET_STATUS': {
      return { ...state, status: action.payload };
    }
    default: {
      return { ...state };
    }
  }
};

export const AuthContext = React.createContext(
  {} as {
    state: AuthState;
    dispatch: Dispatch<AuthActions>;
  },
);

export function AuthProvider(props: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AuthContext.Provider>
  );
}
