import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { API } from 'aws-amplify';
import localforage from 'localforage';
import React from 'react';
import { AuthContext } from '../../../../contexts/auth-context';
import { usePermissions } from '../../../../hooks/use-permissions';
import { AWS_GROUP } from '../../../../types';
import { captureError } from '../../../../utils/capture-error';
import { SelectMeter } from './select-meter';

export interface OptionDetails {
  name: string;
  full: string;
  empty: string;
}

export function ContestMeter() {
  // Hooks
  const { checkPermissions } = usePermissions();
  const isAdmin = checkPermissions([
    AWS_GROUP.ReadAll,
    AWS_GROUP.Staff_ReadOnly,
    AWS_GROUP.Accounting,
    AWS_GROUP.Commissions,
    AWS_GROUP.CommissionApproval,
    AWS_GROUP.Marketing,
  ]);
  // Context
  const {
    state: { user },
  } = React.useContext(AuthContext);

  // State
  const [update, setUpdate] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [contestAmt, setContestAmt] = React.useState(0);
  const [selection, setSelection] = React.useState(
    'https://a02a41c004c75ffe8d91-b19a28bfebdacc37eb56f25bc81c2f2f.ssl.cf1.rackcdn.com/images/arcnaaleads/contest-meter/cabo-filled-v2.png',
  );
  const [emptyMeter, setEmptyMeter] = React.useState(
    'https://a02a41c004c75ffe8d91-b19a28bfebdacc37eb56f25bc81c2f2f.ssl.cf1.rackcdn.com/images/arcnaaleads/contest-meter/cabo-base-v2.png',
  );
  const options: OptionDetails[] = [];

  // Check if user has previously selected a meter
  React.useEffect(() => {
    async function getMeter() {
      const value = await localforage.getItem(`${user.username}-contest-meter`);
      if (value !== null) {
        setEmptyMeter(
          options.find((opt) => opt.name === value)?.empty || options[0].empty,
        );

        setSelection(
          options.find((opt) => opt.name === value)?.full || options[0].full,
        );
      }
    }
    if (options.length > 0) {
      getMeter();
    }

    // eslint-disable-next-line
  }, [update, user.username]);

  // Get contest points data
  React.useEffect(() => {
    let promise: Promise<any> | undefined;
    setLoading(true);
    const fetchData = async () => {
      const path = '/homepage/getContestPoints';
      try {
        promise = API.post('DashboardAPI', path, {});
        const response: {
          status: string;
          data: { contestPoints: number };
        } = await promise;
        if (response.status === 'ok') {
          setContestAmt(response.data.contestPoints / 1000);
        }
      } catch (error) {
        if (!API.isCancel(error)) {
          captureError({ data: { error, request: { path } } });
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    return () => {
      if (promise) {
        API.cancel(promise);
      }
    };
  }, []);

  // Modify value to approximate amout of pixels to set the level of the gauge
  let modifiedValue = 0;
  const maxValue = 250;
  let multiplier = 2.06;
  // Adjust mulitiplier depending on contest value due to uneven spacing in the gauge image
  if (contestAmt <= 78) {
    multiplier = 2.14;
  } else if (contestAmt >= 195) {
    multiplier = 2.04;
  }
  // Calculate value for agents but default to a full gauge for admins
  if (!isAdmin && contestAmt < maxValue) {
    modifiedValue = 515 - contestAmt * multiplier;
  }

  return (
    <Grid container justifyContent="center">
      <Grid item style={{ height: 515, position: 'relative' }}>
        {loading ? (
          <Skeleton
            variant="rectangular"
            width={210}
            height={515}
            style={{ borderRadius: 5 }}
          />
        ) : (
          <React.Fragment>
            <div style={{ position: 'absolute' }}>
              <img
                src={selection}
                alt="contest meter"
                style={{
                  height: 515,
                  width: 210,
                  borderRadius: 5,
                  boxShadow: '3px 3px 5px',
                }}
              />
            </div>
            <div
              style={{
                position: 'relative',
                overflow: 'hidden',
                textAlign: 'center',
                height: modifiedValue,
              }}
            >
              <img
                src={emptyMeter}
                alt="meter"
                style={{
                  height: 515,
                  width: 210,
                  borderRadius: 5,
                }}
              />
            </div>
            {options.length > 0 && (
              <SelectMeter
                options={options}
                username={user.username}
                update={setUpdate}
              />
            )}
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
}
