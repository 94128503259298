import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';

export function DisplayMarkupBox(props: {
  markup: string | null;
  sx?: SxProps;
}) {
  const createMarkup = (__html: string) => {
    return { __html };
  };

  if (props.markup) {
    return (
      <Box sx={props.sx} dangerouslySetInnerHTML={createMarkup(props.markup)} />
    );
  } else {
    return null;
  }
}

export function DisplayMarkup(props: {
  markup: string | null;
  className?: string;
}) {
  const createMarkup = (__html: string) => {
    return { __html };
  };

  if (props.markup) {
    return (
      <div
        className={props.className}
        dangerouslySetInnerHTML={createMarkup(props.markup)}
      />
    );
  } else {
    return null;
  }
}
