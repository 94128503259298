import { TableCellProps } from '@mui/material/TableCell';

export interface ColumnHeader<T> {
  key: keyof T;
  label?: string;
  align?: TableCellProps['align'];
}

export interface TableState {
  page: number;
  rowsPerPage: number;
  sort: 'asc' | 'desc';
  sortBy: string;
}

export enum AWS_GROUP {
  Accounting = 'Accounting',
  AgentDataAdmin = 'AgentDataAdmin',
  AllianceEvents_Admin = 'AllianceEvents_Admin',
  AllianceEvents_CheckIn = 'AllianceEvents_CheckIn',
  ARC_BillingSystem = 'ARC_BillingSystem',
  CarrierFeeds = 'CarrierFeeds',
  CommissionApproval = 'CommissionApproval',
  CommissionMatrix = 'CommissionMatrix',
  Commissions = 'Commissions',
  Contracting = 'Contracting',
  CTR_AgtSpecImpersonation = 'CTR_AgtSpecImpersonation',
  GlobalAdmin = 'GlobalAdmin',
  ImpersonateAll = 'ImpersonateAll',
  LDS_GlobalAdmin = 'LDS_GlobalAdmin',
  LDS_ImpersonateAll = 'LDS_ImpersonateAll',
  LDS_KeyingMgr = 'LDS_KeyingMgr',
  Marketing = 'Marketing',
  PermissionsAdmin = 'PermissionsAdmin',
  ReadAll = 'ReadAll',
  Reconciliation = 'Reconciliation',
  Staff_ReadOnly = 'Staff_ReadOnly',
}

export interface PermissionUser {
  Username: string;
  Email: string;
}

export interface PermissionGroup {
  GroupName: keyof typeof AWS_GROUP;
  DisplayName: string;
  Users?: PermissionUser[];
}

export interface SearchAgentData {
  AgtNo: string;
  AgtName: string | null;
  CellPh: string | null;
  Email: string | null;
  Location: string | null;
  AgtStatus: string | null;
  ComLvl?: string | number | null;
}

export interface WalletDepositData {
  ACHInfoID: number | null;
  Name: string | null;
  Routing: string | null;
  Number: string | null;
  AccountType: string | null;
  DateCreated: string | null;
  DateModified: string | null;
  PayNo: string | null;
}

export interface WalletBillingData {
  AccountNumber: string | null;
  RoutingNumber: string | null;
  AccountType: string | null;
  HasAgreed: boolean | null;
}

export interface WalletAccountData {
  AgtACHId: number;
  Nickname: string | null;
  Routing: string | null;
  Account: string | null;
  AccountType: 'Checking' | 'Savings' | null;
  LastModified: string | null;
  ForComms: boolean | null;
  ForLeadBill: boolean | null;
  IsLeadBillPrimary: boolean | null;
}

export enum PYMNT_DFLT {
  'EVT' = 'EVT',
  'LDS' = 'LDS',
  'PRC' = 'PRC',
}

export interface CustomerPaymentData {
  customerPaymentProfileId: string | null;
  cardNumber: string | null;
  expirationDate: string | null;
  cardType: string | null;
  billToPhone: string | null;
  billToEmail: string | null;
  billToFirstName: string | null;
  billToLastName: string | null;
  billToCompany: string | null;
  billToAddress: string | null;
  billToCity: string | null;
  billToState: string | null;
  billToZip: string | null;
  billToCountry: string | null;
  defaultFor: PYMNT_DFLT[] | null;
}

export interface CustomerData {
  merchantCustomerId: string | null;
  customerProfileId: string | null;
  description: string | null;
  email: string | null;
  username: string | null;
  customerPaymentProfiles: CustomerPaymentData[];
  profileType: string | null;
}

export interface WalletData {
  billing: WalletAccountData[];
  'credit-cards': CustomerData;
}

export interface DebtCCPmntData {
  CCPmntID: number | string;
  BatID: number | string | null;
  DbtPmntTypeID: number | string | null;
  amount: number | string | null;
  transId: number | string | null;
  refId: number | string | null;
  transDesc: string | null;
  paidBy: string | null;
  paidTo: string | null;
  initiatedBy: string | null;
  createdDate: string | null;
}

export enum BillingUserAccess {
  'Allowed' = 1,
  'Not Allowed' = 2,
  'No Access' = 3,
  'Read-Only' = 4,
  'Read/Write' = 5,
}

export interface BillingAgentARData {
  AMId: number;
  TxDate: string | null;
  TxType: string | null;
  TxCode: string | null;
  InvId: string | null;
  Paid: boolean | null;
  Charge: number | null;
  Payment: number | null;
  BalFwd: number | null;
  Method: string | null;
  ChgUser: string | null;
}

export interface BillingSubARData {
  sArId: number;
  arId: BillingARData['AR'];
  name: string | null;
  refId: number | null;
  chgdate: string | null;
  chguser: string | null;
  ccProgram: string | null;
}

export interface BillingARData {
  AR: string;
  ARName: string | null;
  WeekEndDOW: number | null;
  IsPaymentPlan: boolean | null;
  ARActive: boolean | null;
  AllowsInvoicing: boolean | null;
  AllowsCCDrafts: boolean | null;
  AllowsACHDrafts: boolean | null;
  program: string | null;
  ccProgram: string | null;
  name: string | null;
  requiresAdjustmentApproval: boolean | null;
  weekendingDayOverride: number | null;
  weekendingWeekAdjustment: number | null;
  weekendingMonthAdjustment: number | null;
  invoiceStatement: string | null;
  manageable: boolean | null;
  isPaymentPlan: boolean | null;
  interestRate: number | null;
  allowsStatements: boolean | null;
  usesGenericInvoicing: boolean | null;
  hasSubArs: boolean | null;
  gwProgram: string | null;
  accessLevel: number | null;
  subArs: BillingSubARData[] | null;
}

export interface BillingARSelected {
  AR: BillingARData['AR'] | null;
}

export interface BillingARAccountData {
  AgtACHId: number | null;
  Nickname: string | null;
  Routing: string | null;
  Account: string | null;
  AccountType: string | null;
  LastModified: string | null;
  ForComms: boolean | null;
  ForLeadBill: boolean | null;
  IsLeadBillPrimary: boolean | null;
  customerPaymentProfileId: number | null;
  cardNumber: number | null;
  expirationDate: string | null;
  cardType: string | null;
  billToPhone: string | null;
  billToEmail: string | null;
  billToFirstName: string | null;
  billToLastName: string | null;
  billToCompany: string | null;
  billToAddress: string | null;
  billToCity: string | null;
  billToState: string | null;
  billToZip: string | null;
  billToCountry: string | null;
  defaultFor: string[] | null;
  tempID?: string;
}

export interface BillingARProfileData {
  AgtNo: string | null;
  Fname: string | null;
  Mname: string | null;
  Lname: string | null;
  BillLvl: number | null;
  Suffix: string | null;
  Alias: string | null;
  DOB: string | null;
  Email: string | null;
  CellPh: string | null;
  HomePh: string | null;
  BusPh: string | null;
  Fax: string | null;
  AgtStatus: string | null;
  BillUpline: boolean | null;
  IsSuspended: boolean | null;
  PCMember: boolean | null;
}

export interface BillingARPresClubData {
  subName: string | null;
  active: boolean | null;
  canResubscribe: boolean | null;
  mediaCredits: number | null;
  newAgreement: boolean | null;
  dateSigned: string | null;
  dateJoined: string | null;
  dateUpdated: string | null;
  deliveryMethod: string | null;
  inactiveMethod: string | null;
}

export interface BillingARAllAccessData {
  active: boolean | null;
  committedThru: string | null;
  payFrequency: string | null;
  signedUp: string | null;
  paidThru: string | null;
}

export interface BillingServiceStatusData {
  caption: string | null;
  active: boolean | null;
  canChange: boolean | null;
}

export interface BillingServiceStatusReport {
  leads: BillingServiceStatusData;
  collections: BillingServiceStatusData;
  'presidents-club': BillingServiceStatusData;
  'all-access': BillingServiceStatusData;
}

export interface BillingBankChangeData {
  ChgDate: string | null;
  OldRouting: string | null;
  OldAccount: string | null;
  NewRouting: string | null;
  NewAccount: string | null;
  ForComms: boolean | null;
  ForLeadBill: boolean | null;
  tempID?: string;
}

export interface BillingARNoteData {
  NoteId: number | null;
  EntryDate: string | null;
  Note: string | null;
  AdminUser: string | null;
}

export interface BillingUserData {
  UserId: number;
  AdAccount: string | null;
  Active: boolean | null;
  Name: string | null;
  SSOLogin: string | null;
}

export interface BillingPermissionData {
  ProgramId: number;
  Program: string | null;
  Name: string | null;
  Desc: string | null;
  AtId: number | null;
  AvId: number | null;
  AvValue: string | null;
}

export interface BillingTransactionTypeData {
  TxType: string | null;
  Description: string | null;
  Sign: string | null;
}

export interface BillingTransactionCodeData {
  TxCode: string | null;
  Description: string | null;
}

export interface BillingMethodData {
  Method: string | null;
}

export interface BillingAdjustmentTypes {
  'transaction-types': BillingTransactionTypeData[];
  'transaction-codes': BillingTransactionCodeData[];
  methods: BillingMethodData[];
}

export interface BillingAdjustmentData {
  AdjId: number;
  AgtNo: string | null;
  AgtName: string | null;
  TxDate: string | null;
  TxType: string | null;
  TxCode: string | null;
  InvId: string | null;
  Chg: number | null;
  Pmt: number | null;
  Method: string | null;
  AdminUser: string | null;
  Status: string | null;
}

export interface BillingPaymentPlanData {
  AgtNo: string | null;
  AmtOwed: number | null;
  ApplyInterest: boolean | null;
  Bill: boolean | null;
  HoldInterest: string | null;
  HoldUntil: string | null;
  PayAmt: number | null;
  PPId: number | null;
  AgtName: string | null;
  AR: string | null;
  InterestRate: number | null;
  tempID?: string;
}

export interface BillingAmountSearchData {
  agentNo: string | null;
  agentName: string | null;
  arId: number | null;
  arName: string | null;
  date: string | null;
  type: string | null;
  code: string | null;
  invoice: string | null;
  charge: number | null;
  payment: number | null;
  method: string | null;
  username: string | null;
  stamp: string | null;
  tempID?: string;
}

export interface RollupData {
  RId: number;
  WeekEndDate: string | null;
  FromAgtNo: string | null;
  FromAgtName: string | null;
  ToAgtNo: string | null;
  ToAgtName: string | null;
  Amt: number | null;
  Reason: string | null;
  Stamp: string | null;
  Roll: 0 | 1 | null;
}

export interface RollupSummaryData {
  WeekEndDate: RollupData['WeekEndDate'];
  TotalAmount: RollupData['Amt'];
  rollups: RollupData[];
}

export interface DraftAdjustmentData {
  DAId: number;
  AgtNo: string | null;
  Approved: boolean | null;
  Denied: boolean | null;
  Suspended: boolean | null;
  Amt: number | null;
  AdminUser: string | null;
  ChgDate: string | null;
  WeekEndDate: string | null;
  AR: string | null;
  BillLvl: number | null;
  Email: string | null;
  Fname: string | null;
  Mname: string | null;
  Lname: string | null;
  HasCorp: boolean | null;
  Suffix: string | null;
  ARName: string | null;
}

export interface NoteData {
  ComNotesID: number | null;
  UniqComID: PolicyData['UniqComID'];
  PolTrackID: PolicyData['PolTrackID'];
  CarrierID: PolicyData['CarrierID'];
  Note: string | null;
  DisplayToAgent: boolean | null;
  ModifiedDate: string | null;
  ModifiedBy: string | null;
  CreatedDate: string | null;
  CreatedBy: string | null;
}

export interface PolicyData {
  UniqComID: string | null;
  ComID: number | null;
  CorID: number | null;
  FutComID: number | null;
  CMGenComID: number | null;
  RecID: number | null;
  ArcHist: number | null;
  Orig: number | null;
  Status: string | null;
  Carrier: string | null;
  PolNo: string | null;
  PolTrackID: number | null;
  PlanName: string | null;
  IMOAmtPd: number | null;
  IMOComRate: number | null;
  TranType: string | null;
  IMOAdvRate: number | null;
  IMONetPrem: number | null;
  Mode: number | null;
  ModePrem: number | null;
  AgtComPrem: number | null;
  AgtAdvRate: number | null;
  AgtNetPrem: number | null;
  AgtComRate: number | null;
  SumMult: number | null;
  TotAgtPay: number | null;
  AprvdDate: string | null;
  Split: number | null;
  AsErnd: number | null;
  Cap: number | null;
  WrtAgtPay: number | null;
  Ctrld: number | null;
  InsName: string | null;
  WrtName: string | null;
  WrtNo: string | null;
  WrtCarNo: string | null;
  SumIMONetPrem: number | null;
  SumAgtPay: number | null;
  GrossAgtPay: number | null;
  FileDate: string | null;
  MinFileDate: string | null;
  ProdType: string | null;
  RecType: string | null;
  ApSigDate: string | null;
  MatAct: number | null;
  IMOPremAct: number | null;
  AgtPremAct: number | null;
  MagicNo: number | null;
  PrevChb: number | null;
  IsAnty: number | null;
  HierMult: number | null;
  GloMult: number | null;
  CntComID: number | null;
  CntAgtComRate: number | null;
  CntSplit: number | null;
  CntPlanName: number | null;
  Research: boolean | null;
  IMOGAPFlag: boolean | null;
  GAPAgtFlag: boolean | null;
  IMOAgtFlag: boolean | null;
  AgtNegFlag: boolean | null;
  CarrierID: number | null;
  CarrierMagic: number | null;
  ComSrcID: number | null;
  LastModifiedBy: string | null;
  LastModifiedDate: string | null;
  ApprovedBy: string | null;
}

export interface PolicyHierarchy {
  AgtNo: string | null;
  AgtName: string | null;
  Mult: number | null;
  Depth: number | null;
}

export interface PolicyGlo {
  AgtNo: string | null;
  AgtName: string | null;
  Mult: number | null;
}

export interface CalculatedReportTotals {
  // Personal
  personalFYPositive: number;
  personalFYNegative: number;
  personalRNPositive: number;
  personalRNNegative: number;
  personalPositive: number;
  personalNegative: number;
  // Override
  overrideFYPositive: number;
  overrideFYNegative: number;
  overrideRNPositive: number;
  overrideRNNegative: number;
  overridePositive: number;
  overrideNegative: number;
  // GLO
  gloFYPositive: number;
  gloFYNegative: number;
  gloRNPositive: number;
  gloRNNegative: number;
  gloPositive: number;
  gloNegative: number;
  // Commissions
  commissionsPositive: number;
  commissionsNegative: number;
  // Other
  otherPositive: number;
  otherNegative: number;
  // Sums
  sumPositive: number;
  sumNegative: number;
  sumFinal: number;
}

export interface CommissionReport {
  commissions: PaymentCommission[];
  chargeBackPayments: PaymentChargeback[];
  otherDebtPayments: PaymentDebt[];
  adjustments: PaymentAdjustment[];
  remainingDebtBalance: number | null;
  YTDTotal: number | null;
}

export interface PaymentCommission {
  CorID: number | null;
  ListID: number | null;
  ComID: number | null;
  FutComID: number | null;
  RecID: number | null;
  Carrier: string | null;
  PolNo: string | null;
  PayNo: string | null;
  PayName: string | null;
  WrtNo: string | null;
  WrtName: string | null;
  InsName: string | null;
  PlanName: string | null;
  PayDate: string | null;
  AgtComPrem: number | null;
  AgtNetPrem: number | null;
  Split: number | null;
  PayType: string | null;
  Mult: number | null;
  AgtComRate: number | null;
  AgtAdvRate: number | null;
  AgtPay: number | null;
  // Created and used on the client
  // ---
  // tempID is added to PaymentCommission on the client
  // It is not optional and will not come from the API
  tempID: string;
}

export interface PaymentChargeback {
  PolNo: string | null;
  WrtNo: string | null;
  WrtName: string | null;
  Carrier: string | null;
  InsName: string | null;
  PlanName: string | null;
  PayDate: string | null;
  AgtComPrem: number | null;
  Split: number | null;
  PayType: string | null;
  Mult: number | null;
  AgtComRate: number | null;
  AgtAdvRate: number | null;
  AgtPay: number | null;
  // Created and used on the client
  // ---
  // tempID is added to PaymentChargeback on the client
  // It is not optional and will not come from the API
  tempID: string;
}

export interface PaymentDebt {
  CreatedDate: string | null;
  DbtType: string | null;
  DbtDesc: string | null;
  TotDbtAmt: number | null;
  TotAmtPaid: number | null;
  TotAmtUnpaid: number | null;
  AgtPay: number | null;
  RolledUpFrom: string | null;
  // Created and used on the client
  // ---
  // tempID is added to PaymentDebt on the client
  // It is not optional and will not come from the API
  tempID: string;
}

export interface PaymentAdjustment {
  AdjReason: string | null;
  AgtPay: number | null;
  CreatedDate: string | null;
  // Created and used on the client
  // ---
  // tempID is added to PaymentDebt on the client
  // It is not optional and will not come from the API
  tempID: string;
}

export interface PaymentType {
  DbtPmntTypeID: string;
  DbtPmntType: string;
}

export interface AdjCategory {
  AdjTypeID: number;
  AdjType: string | null;
  PBPct: number | null;
  Priority: number | null;
  Active: 1 | 0 | null;
}

export interface AccountingAdj {
  AdjID: number | null;
  PayNo: string | null;
  BatID: number | null;
  AdjTypeID: number | null;
  AdjReason: string | null;
  AgtPay: number | null;
  is1099Eligible: 0 | 1 | null;
  isDebtExempt: 0 | 1 | null;
  CreatedDate: string | null;
}

export interface AccountingDbt {
  DbtID: number | null;
  UniqComID: string | null;
  ListID: number | null;
  AdjTypeID: number | null;
  DbtDesc: string | null;
  PayNo: string | null;
  TotDbtAmt: number | null;
  TotAmtPaid: number | null;
  TotAmtUnpaid: number | null;
  PauseUntil: string | null;
  Status: string | null;
  PaidDate: string | null;
  PolNo: string | null;
  FixedAmt: number | null;
  PBPct: number | null;
  RollUpFmAgt: string | null;
  RollupDate: string | null;
  is1099Eligible: number | null;
  isCancelled: number | null;
  CancelReason: string | null;
  BatID: number | null;
  CreatedDate: string | null;
  CreatedBy: string | null;
  ModifiedDate: string | null;
  ModifiedBy: string | null;
}

export interface DebtAgtSummary {
  PayNo: string;
  PayName: string | null;
  AgtStatus: string | null;
  UplineNo: string | null;
  UplineName: string | null;
  TotAmtUnpaid: number | null;
  OldestDebtDate: string | null;
  LastPmntDate: string | null;
}

export interface DebtLineItem {
  DbtID: number;
  UniqComID: string | null;
  AdjTypeID: number | null;
  AdjType: string | null;
  DbtDesc: string | null;
  TotDbtAmt: number | null;
  TotAmtPaid: number | null;
  TotAmtUnpaid: number | null;
  PolNo: string | null;
  CancelReason: string | null;
  Status: 'UNPAID' | 'PAID' | 'ROLLED' | 'PAUSED' | 'CANCELLED' | null;
  RollUpFmAgt: string | null;
  CreatedDate: string | null;
  CreatedBy: string | null;
  ModifiedDate: string | null;
  ModifiedBy: string | null;
}

export interface Listing {
  UniqComID: string | null;
  CorID: number | null;
  ListID: number | null;
  ComID: number | null;
  FutComID: number | null;
  RecID: number | null;
  Carrier: string | null;
  PolNo: string | null;
  PayNo: string | null;
  PayName: string | null;
  WrtNo: string | null;
  WrtName: string | null;
  InsName: string | null;
  PlanName: string | null;
  PayDate: string | null;
  AgtComPrem: number | null;
  AgtNetPrem: number | null;
  Split: number | null;
  PayType: string | null;
  Mult: number | null;
  AgtComRate: number | null;
  AgtAdvRate: number | null;
  AgtPay: number | null;
  BatID: number | null;
  ApprovedBy: string | null;
  // Created and used on the client
  // AgtPay summed by RecID
  TotAgtPay?: number;
  // Mult summed by RecID
  TotMult?: number;
  // PolicyListing's grouped by RecID
  Listings?: Listing[];
  // ARCHistoryID
  // This ID is a combination of the UniqComID and the PayDate
  ARCHistoryID?: string;
}

export interface Payroll {
  BatchId: string | number | null;
  PayDate: string | null;
}

export interface CRMAgent {
  AgentNumber: string;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  AgentStatus: string;
  AgentLevel: string;
  RecAgentNumber: string;
  RecFirstName: string;
  RecLastName: string;
  RecMiddleName: string;
  Address1: string;
  Address2: string;
  City: string;
  State: string;
  Zip: string;
  Email: string;
  AlternateEmail: string;
  HomePhone: string;
  CellPhone: string;
  BusinessPhone: string;
  Fax: string;
  DateEntered: string;
  RTSDate: string | null;
  YTDAmount: string | null;
  Latitude: number | null;
  Longitude: number | null;
}

export interface CRMContact {
  ContactId: string;
  Username: string;
  ContactTypeId: '1' | '2' | '3' | '4';
  ContactType: string;
  FirstName: string | null;
  LastName: string | null;
  Phone: string | null;
  ContactStatus:
    | 'New'
    | 'N/I'
    | 'Not Now'
    | 'Follow Up'
    | 'In Class'
    | 'Paperwork'
    | 'No Answer'
    | "Didn't Book"
    | 'Booked'
    | 'Call Back'
    | 'Door Knock'
    | 'No Sale'
    | 'Sold Policy'
    | 'Pending'
    | 'Active'
    | 'Lapsing'
    | 'Not Active'
    | null;
  Address1: string | null;
  Address2: string | null;
  City: string | null;
  State: string | null;
  Zip: string | null;
  Email: string | null;
  DateOfBirth: string | null;
  Created: string | null;
  Modified: string | null;
  County: string | null;
  LeadSource: string | null;
  ProspectLicensed: string | null;
  Reviews?: CRMPolicyReview[];
  Conversions?: CRMPolicyReview[];
  Chargebacks?: CRMPolicyReview[];
  Medicare?: CRMBirthday | null;
  Birthday?: CRMBirthday | null;
  MoOChildrensWL?: CRMBirthday | null;
  ForestersChildrensWL?: CRMBirthday | null;
  Carriers?: string | null;
  carriers?: string[];
  Tags?: string;
  tags?: string[];
  // contactTags is an array of custom tags for the Today tab of CRM
  contactTags?: string[];
  Latitude: number | null;
  Longitude: number | null;
}

export interface CRMLeadDetail {
  ContactId: number;
  Username: string;
  Age: string;
  // LeadGuid: null;
  // OasysLink: string;
  Expire: string;
  DistDate: string;
  Allocated: string;
  LeadId?: string;
  LeadID?: string;
  // UniqId: string;
  LeadProduct: string;
  LeadSource: string;
  LeadLanguage: string;
  DistSource: string;
  LeadType: string;
  IsFree: boolean;
  UsernameFrom: string;
  Fips: string;
  County: string;
  Region: string;
  Clients: { fname: string; lname: string; type: string }[] | null;
  Phones: { display: string; value: string }[] | null;
  Emails: { display: string; value: string }[] | null;
  MetaData: { display: string; value: string }[] | null;
}

export interface CRMPolicyReview {
  ContactId: number;
  ClientInfoId: number;
  AppId?: string;
  FirstName: string;
  LastName: string;
  ContactStatus: string;
  PolNo: string;
  Premium: number;
  Issued: string;
  LatestChbPayDate?: string;
  PolicyReview?:
    | '6mo'
    | '12mo'
    | 'CFG SafeShield'
    | 'Foresters Strong Foundation'
    | 'Mutual of Omaha Term Life Express';
  Completed: boolean;
}

export interface CRMBirthday {
  ContactId: number;
  FirstName: string;
  LastName: string;
  ContactStatus: string;
  DateOfBirth: string;
  Completed: boolean;
  ClientInfoId?: number;
}

export interface CRMToday {
  policyReviews: CRMPolicyReview[];
  conversions: CRMPolicyReview[];
  chargebacks: CRMPolicyReview[];
  medicare: CRMBirthday[];
  birthdays: CRMBirthday[];
  foresters_children_wl: CRMBirthday[];
  moo_children_wl: CRMBirthday[];
}

export interface ARMTodayTask {
  AMId: string | null;
  AgentNo: string | null;
  fname: string | null;
  lname: string | null;
  MTypeName: string | null;
  MilestoneDate: string | null;
  Completed: string | null;
}

export interface ARMToday {
  FirstGLOBonus?: ARMTodayTask[];
  FirstOverride?: ARMTodayTask[];
  FirstFastractedAgent?: ARMTodayTask[];
  FirstARCDeposit?: ARMTodayTask[];
  DOB?: ARMTodayTask[];
}

export enum ContentCategoryName {
  'Leaderboards' = 'Leaderboards',
  'News & Notes' = 'News & Notes',
  'Upcoming Events' = 'Upcoming Events',
  'Contests' = 'Contests',
}

export interface ContentCategory {
  CategoryId: number;
  Category: keyof typeof ContentCategoryName | null;
  DateCreated: string | null;
}

export interface ContentDocument {
  DdocId: number;
  DdocIndex: number | null;
  CategoryId: number | null;
  Category?: ContentCategory['Category'];
  Title: string | null;
  ThumbnailName: string | null;
  FileName: string | null;
  ThumbnailContent: string | null;
  FileContent: string | null;
  Description: string | null;
  URL: string | null;
  DateCreated: string | null;
  DateUpdated: string | null;
  Deleted: number | null;
}

export enum CarrierParent {
  'ancillary-product' = 'ancillary-product',
  'medicare-advantage' = 'medicare-advantage',
  'medicare-supplement' = 'medicare-supplement',
}

export interface ContentCarrier {
  CCId: number;
  ParentCarrier: keyof typeof CarrierParent | null;
  CarrierCode: string;
  Title: string | null;
  Address: string | null;
  Phone: string | null;
  AgentSite: string | null;
  Eapp: string | null;
  Quotes: string | null;
  LogoFileName: string | null;
  CommissionFileName: string | null;
  DateCreated: string | null;
  DateUpdated: string | null;
  // attachments is an array ContentCarrierLink created on the client
  attachments?: ContentCarrierLink[];
}

export enum CarrierCategory {
  'Product Information' = 'Product Information',
  'Product' = 'Product',
  Training = 'Training',
  Videos = 'Videos',
}

export interface ContentCarrierCategory {
  CCatId: string;
  CategoryName: keyof typeof CarrierCategory;
  DateCreated: string;
}

export enum CarrierSubcategory {
  'Life Insurance' = 'Life Insurance',
  Annuities = 'Annuities',
  Dental = 'Dental',
  'Hospital Indemnity' = 'Hospital Indemnity',
  'First Diagnosis Cancer' = 'First Diagnosis Cancer',
  'Short Term Recovery Care' = 'Short Term Recovery Care',
  'Outline of Coverage' = 'Outline of Coverage',
  'Consumer Brochures' = 'Consumer Brochures',
  'Sales Presentations' = 'Sales Presentations',
}

export interface ContentCarrierSubcategory {
  CSubCatId: string;
  DateCreated: string;
  SubCategoryName: keyof typeof CarrierSubcategory;
}

export interface ContentCarrierLink {
  CAId: string;
  CCId: string | null;
  CAIndex: string | number | null;
  UploadedFileName: string | null;
  FileName: string | null;
  URL: string | null;
  CategoryName: string | null;
  SubCategoryName: string | null;
}

export interface CarrierHeaderLink {
  Title: string;
  CarrierCode: string;
  EApp: string | null;
}

export interface AgentSelect {
  agentno: string;
  fname: string | null;
  mname: string | null;
  lname: string | null;
  agentname: string | null;
  hom_addr1: string | null;
  hom_city: string | null;
  hom_state: string | null;
  hom_zip: string | null;
  status: string | null;
}

export interface ClientCommission {
  ListID: number | null;
  ComID: number | null;
  Carrier: string | null;
  PolNo: string | null;
  PolTrackID: number | null;
  PlanName: string | null;
  IMOComRate: number | null;
  Split: number | null;
  AgtComPrem: number | null;
  WrtNo: string | null;
  WrtName: string | null;
  InsName: string | null;
  PayNo: string | null;
  PayName: string | null;
  AgtComRate: number | null;
  AgtAdvRate: number | null;
  Mult: number | null;
  AgtPay: number | null;
  PayType: string | null;
  AprvdDate: string | null;
  Ctrld: number | null;
  BatID: number | null;
  RecID: number | null;
  IsAnty: number | null;
  AgtStatus: string | null;
  CarrierID: number | null;
  ComSrcID: number | null;
  PayTypeID: number | null;
  // tempID is added to ClientCommission on the client
  // It is not optional and will not come from the API
  tempID: string;
}

export interface ContactActivity {
  ContactId: string | null;
  ClientInfoId: string | null;
  Username: string | null;
  IssuedDate: string | null;
  PolNo: string | null;
  Carrier: string | null;
  AP: string | null;
  FaceAmount: string | null;
  AppId: string | null;
  ContactStatus: string | null;
}

export interface ContactMsg {
  CMId: number;
  ContactId: number;
  ContactPhone: string;
  Username: string;
  MsgType: string;
  Inbound: boolean;
  Msg: string;
  DateCreated: string;
  UserVirtualPhone: string;
  UserVirtualPhoneActive: boolean;
  Concatenated: boolean;
  ConcatRef: string;
  ConcatPart: number;
  ConcatTotal: number;
  MsgRead: boolean;
  MsgSent: number;
  MsgSentError: string;
}

export interface ContactDial {
  CDId: string;
  DateAdded: string;
}

export interface ContactAppointment {
  CAId: string;
  DateAdded: string;
  EventStart?: string;
}

export interface ContactNote {
  NoteId: string;
  ContactId: string;
  Note: string;
  DateAdded: string;
  Deleted: string;
}

export interface ContactDocument {
  DocId: string;
  ContactId: string;
  Type: string;
  FileName: string;
  Description: string;
  DateUploaded: string;
  Deleted: string;
}

export interface ContactGreenSheet {
  ContactId: string;
  ClientFirstName: string;
  ClientLastName: string;
  ClientDob: string;
  ClientHeight: string;
  ClientWeight: string;
  ClientTobacco: string;
  ClientAddress: string;
  ClientCity: string;
  ClientState: string;
  ClientZip: string;
  ClientEmployer: string;
  ClientEmployerLength: string;
  ClientIncome: string;
  ClientRetired: string;
  ClientLifePoliciesFaceValue: string;
  ClientLifePoliciesOptions: string;
  ClientHealthInsuranceProvider: string;
  SpouseFirstName: string;
  SpouseLastName: string;
  SpouseDob: string;
  SpouseHeight: string;
  SpouseWeight: string;
  SpouseTobacco: string;
  SpouseAddress: string;
  SpouseCity: string;
  SpouseState: string;
  SpouseZip: string;
  SpouseEmployer: string;
  SpouseEmployerLength: string;
  SpouseIncome: string;
  SpouseRetired: string;
  SpouseLifePoliciesFaceValue: string;
  SpouseLifePoliciesOptions: string;
  SpouseHealthInsuranceProvider: string;
  HomeOwner: string;
  Lender: string;
  HomeownerInsuranceProvider: string;
  ValueOfHome: string;
  MortgageAmount: string;
  MortgageMonthlyPayment: string;
  AutoLoan: string;
  AutoLoanLender: string;
  AutoInsuranceProvider: string;
  AutoLoanPaymentAmount: string;
  Savings: string;
  mutualFundsStocks: string;
  iraAnnuities401K: string;
  NetWorth: string;
  AnyHealthConcern: string;
  MedicationsReasonForTaking: string;
  Children: string;
  AnyCriminalDrivingConcern: string;
  UsCompleteApplication: string;
  Phone: string;
  TimeToContact: string;
  Email: string;
}

export interface CalendarSource {
  CalId: string;
  CalSourceId: number;
  Username: string;
  CalSourceDesc: string;
  CalUrl: string;
  TextColor: string;
  BgColor: string;
  BorderColor: string;
}

export interface CalendarEvent {
  EventId: number | null;
  CalId: string;
  ICalUID: string;
  TextColor: string;
  BgColor: string;
  BorderColor: string;
  EventStart: string;
  AllDay: boolean | null;
  EventEnd: string;
  EventLocation: string;
  Summary: string;
  EventDesc: string;
  EventType: string | null;
  Recurrence: boolean | null;
  RecurrenceFrequency: string | null;
  RecurrenceBy: string | null;
  RecurrenceByValue: string | null;
  RecurrenceUntil: string | null;
  CreatedBy: string | null;
  DateCreated: string;
  ModifiedBy: string | null;
  DateModified: string;
  ContactId: number | null;
}

export interface NotificationText {
  CMId: number;
  ContactId: number;
  ContactPhone: string;
  Username: string;
  MsgType: string;
  Inbound: boolean;
  Msg: string;
  DateCreated: string;
  UserVirtualPhone: string;
  UserVirtualPhoneActive: boolean;
  Concatenated: boolean;
  ConcatRef: string;
  ConcatPart: number;
  ConcatTotal: number;
  MsgRead: boolean;
  MsgSent: number;
  MsgSentError: string;
}

export interface NotificationAction {
  NId: string;
  Id: string;
  ContactId: string;
  Username: string;
  NotificationType: string;
  FileName: string | null;
  DateAdded: string;
  Read: '0' | '1';
}

export interface NachaEvent {
  BPId: number;
  BatchId: number | null;
  PayDate: string | null;
  CreatedBy: string | null;
  CreatedDate: string | null;
  CurrentStatus: string | null;
  BatchStatus: number | null;
  Progress: number | null;
  Total: number | null;
  GeneratedDate: string | null;
  NachaFilename: string | null;
  NachaTotal: number | null;
  ApprovedDate: string | null;
  ApprovedBy: string | null;
  Error: string | null;
}

export interface DebtDownline {
  PayNo: string | null;
  PayFName: string | null;
  PayLName: string | null;
  AgtStatus: string | null;
  TotAmtUnpaid: number | null;
  UplineNo: string | null;
  UplineName: string | null;
  ManagerNo: string | null;
  ManagerName: string | null;
  OldestDebtDate: string | null;
  LastPmntDate: string | null;
  // Column used in displaying button for Paying on debt
  Pay?: number | null;
}

export interface DebtPayment {
  AgtPay: number | null;
  BatID: number | null;
  CreatedDate: string | null;
}

export interface DebtChargeback {
  DbtID: number | null;
  PolNo: string | null;
  WrtNo: string | null;
  WrtName: string | null;
  Carrier: string | null;
  InsName: string | null;
  PlanName: string | null;
  PayDate: string | null;
  AgtComPrem: number | null;
  Split: number | null;
  PayType: string | null;
  Mult: number | null;
  AgtComRate: number | null;
  AgtAdvRate: number | null;
  AgtPay: number | null;
  TotAmtUnpaid: number | null;
  Status: string | null;
  RollUpFmAgt: string | null;
}

export interface DebtOther {
  CreatedDate: string | null;
  DbtType: string | null;
  DbtDesc: string | null;
  TotDbtAmt: number | null;
  TotAmtPaid: number | null;
  TotAmtUnpaid: number | null;
  // Created and used on the client
  // ---
  // tempID is added to DebtOther on the client
  // It is not optional and will not come from the API
  tempID: string;
}

export interface DebtUnpaidDownline {
  PayNo: string | null;
  PayName: string | null;
  UplineNo: string | null;
  UplineName: string | null;
  ManagerNo: string | null;
  ManagerName: string | null;
  AgtStatus: string | null;
  AgtPay: number | null;
  // Created and used on the client
  // ---
  // tempID is added to DebtUnpaidDownline on the client
  // It is not optional and will not come from the API
  tempID: string;
}

export interface DebtReport {
  chargebackDebt: DebtChargeback[];
  otherDebt: DebtOther[];
  AutomatedAdjustments: [];
  UnpaidDownlineDebt: DebtUnpaidDownline[];
}

export interface PendingBusiness {
  AgtNo: string | null;
  AgtName: string | null;
  PendCnt: string | null;
  IPCnt: string | null;
  CBCnt: string | null;
  IPCntPrevYr: string | null;
  CBCntPrevYr: string | null;
}

export interface PendingPolicy {
  Carrier: string | null;
  PolNo: string | null;
  AgtNo: string | null;
  AgtName: string | null;
  PolStat: string | null;
  FaceAmt: string | null;
  AnnPayAmt: string | null;
  Plan: string | null;
  InsName: string | null;
  PolEffDate: string | null;
}

export interface PendingRequirement {
  ReqCode: string | null;
  ReqDesc: string | null;
  ReqDate: string | null;
  StatDate: string | null;
  Notes: string | null;
  tempID?: string;
}

export interface SubmittedBusiness {
  AgtNo: string | null;
  AgtName: string | null;
  PolNo: string | null;
  Fname: string | null;
  Lname: string | null;
  LastActDate: string | null;
  AppID: string | null;
  Carrier: string | null;
  AnnPrem: string | null;
  PolStat: string | null;
  Plan: string | null;
  StatDate: string | null;
  Notes: string | null;
  PolEffDate: string | null;
}

export interface ActivityPlan {
  AgentId: number;
  AgentNumber: string;
  AgentName: string;
  Dials: number;
  Appointments: number;
  Contacts: number;
  Sits: number;
  SubmittedAPPs: number;
  SubmittedAP: number;
  Referrals: number;
  Found: number;
  DoorKnocks: number;
  AnnuityApps: number;
  AnnuityAP: number;
  Interviews: number;
  FasTracts: number;
  NewInSchool: number;
  WidthContracts: number;
  FirstSubmit: number;
  FirstIssuePaid: number;
}

export interface AgentIncrementalChargeback {
  FirstName: string;
  LastName: string;
  AgentId: string;
  Percentile: string | null;
}

export interface HierarchyDeposited {
  Name: string;
  Week$: number | null;
  MTD: number | null;
  YTD: number | null;
}

export interface UpcomingEvents {
  name: string;
  url: string;
  image: string;
}

export interface DepositSummary {
  date: string;
  totalComms: number;
  writingComms: number;
  overrideComms: number;
  gloComms: number;
}

export interface LookupAgentList {
  AgentNumber: string;
  Status: string;
  Name: string;
  Location: string;
}

export interface AgentProfile {
  agentno?: string;
  status: string;
  securityCode: string;
  firstName: string;
  middleInitial: string;
  lastName: string;
  preferredName: string;
  dob: string;
  ssn: string;
  companyName: string;
  taxID: string;
  email: string;
  businessEmail: string;
  homePhone: string;
  cellPhone: string;
  businessPhone: string;
  faxNumber: string;
  homeAddress: string;
  homeAddress2: string;
  homeCity: string;
  homeState: string;
  homeZip: string;
  businessAddress: string;
  businessAddress2: string;
  businessCity: string;
  businessState: string;
  businessZip: string;
  npn: string;
}

export interface AccountDetails {
  name: string;
  agentNo: string;
  homeAddress: string;
  homeAddress2: string;
  homeCity: string;
  homeState: string;
  homeZip: string;
  busAddress: string;
  busAddress2: string;
  busCity: string;
  busState: string;
  busZip: string;
  sigName: string;
  sigHash: string;
  sigStamp: string;
  directUpline: string;
  agencyMgr: string;
  recordCreated: string;
  recordUpdated: string;
  lastAccess: string;
  overrideTime: string;
  overrideBy: string;
  ftStatus: string;
  manApproval: string;
  pendingMgr: string;
  contractLevel: string;
  isActive: string;
  isAllowed: string;
  isManager: string;
  hasBilling: string;
  gmrStatus: string;
  signedPAC: string;
}

export interface PopUpData {
  PopupId: number | string | null;
  PopupFileName: string | null;
  PopupFilePath: string | null;
  Title: string | null;
  Frequency: 'once' | 'weekly' | 'monthly' | null;
  Text: string | null;
  DateCreated: string | null;
  DateUpdated: string | null;
  Deleted: number | null;
  buttons?: { label?: string; url?: string }[];
}

export interface RecruitingChart {
  WeekOrMonth: string;
  Recruits: number;
  FasTracts: number;
}

export interface SalesChartResData {
  EndOfMonthOrDynamicWeek: string;
  IPAP: number;
  UniqueWritersOrNewApp: number;
}

export interface BadgeData {
  Area: number;
  BadgeActive: number;
  BadgeTitle: string;
  BadgeType: string;
  DefaultImgID: string;
  ImgFileLocation: string;
  ImgID: string;
  ImgType: string;
  Intent: string;
  ShortDesc: string;
}

export interface AccountingReportData {
  BatchId: number | null;
  PayNo: string | null;
  AgtName: string | null;
  AgtPay: number | null;
  CanPay: number | null;
  AdjType: string | null;
}

export interface HotspotsData {
  MeetingId?: number | string | null;
  HostName: string | null;
  HostImage: string | null;
  Location: string | null;
  Description: string | null;
  DateTime: string | null;
}

export interface SentMessageLog {
  MCId: number;
  Username: string;
  AgentNumber: string;
  AgentName: string;
  AgentPhone: string;
  MsgType: string;
  Outbound: boolean;
  Msg: string;
  DateCreated: string;
  MsgSentStatus: string;
  MsgSentError: string;
  MsgSentDate: string;
}

export interface HierarchyItem {
  AgtNo: string;
  AgtName: string;
  Level: number;
  Depth: number;
}

export interface AgentProfileData {
  AgtNo: string | null;
  AgtStatus: string | null;
  Level: number | null;
  AgtType: string | null;
  AgtName: string | null;
  AliasName: string | null;
  Prefix: string | null;
  Fname: string | null;
  Mname: string | null;
  Lname: string | null;
  Suffix: string | null;
  Title: string | null;
  Gender: string | null;
  Last4SSN: string | null;
  DOB: string | null;
  Email: string | null;
  Email2: string | null;
  HomePh: string | null;
  CellPh: string | null;
  Fax: string | null;
  HomeAdd: string | null;
  HomeAptSte: string | null;
  HomeCity: string | null;
  HomeSt: string | null;
  HomeZip: string | null;
  HomeCnty: string | null;
  MailToAdd: string | null;
  MailToAdptSte: string | null;
  MailToCity: string | null;
  MailToSt: string | null;
  MailToZip: string | null;
  Recruiter: string | null;
  RecName: string | null;
  DrivLic: string | null;
  DrivLicSt: string | null;
  MaidName: string | null;
  MaritalStatus: string | null;
  SpouseName: string | null;
  HasCorp: 0 | 1 | null;
  CorpName: string | null;
  TaxID: string | null;
  BusAdd: string | null;
  BusAptSte: string | null;
  BusCity: string | null;
  BusSt: string | null;
  BusZip: string | null;
  BusPh: string | null;
  Website: string | null;
  NPN: string | null;
  PrevAdd: string | null;
  PrevAptSte: string | null;
  PrevCity: string | null;
  PrevSt: string | null;
  PrevZip: string | null;
  PresAddLth: string | null;
  PrevAddLth: string | null;
  Licensed: 0 | 1 | null;
  USCitizen: 0 | 1 | null;
  ContAuth: 0 | 1 | null;
}

export interface AdminAgentNoteData {
  NoteId: string;
  AgtNo: string | null;
  Note: string | null;
  Username: string | null;
  CreatedDate: string | null;
}

export interface VideoData {
  VideoId?: number;
  VideoSlug?: string | null;
  Title?: string | null;
  Description?: string | null;
  EmbedUrl?: string | null;
  ThumbnailFile?: string | null;
  ThumbnailUrl?: string | null;
  UploadDate?: string | null;
  Duration?: number | null;
}

export interface BillingCCData {
  ccId: string | null;
  system: string | null;
  type: string | null;
  accountEnd: string | null;
  lastUpdated: string | null;
  ccExpires: string | null;
  ccFirstName: string | null;
  ccLastName: string | null;
  ccAddress: string | null;
  ccCity: string | null;
  ccState: string | null;
  ccZip: string | null;
  isIPPrimary: boolean | null;
  isInvoicePrimary: boolean | null;
  label: string | null;
}
