import React from 'react';
import { animated, useSpring } from 'react-spring';

export interface AnimateInProps extends React.HTMLAttributes<HTMLDivElement> {
  // The direction to animate in to
  to?: 'top' | 'right' | 'bottom' | 'left';
  // The amount of pixels to move
  pixels?: number;
  // Don't fade the children in with opacity
  nofade?: 'true' | 'false';
  // Don't slide the children in with transform
  notransform?: 'true' | 'false';
}

export const AnimateIn = React.forwardRef<HTMLDivElement, AnimateInProps>(
  (props, ref) => {
    // Default `to` will be `left`
    const to = props.to ? props.to : 'left';
    // Default `pixels` will be `10`
    const pixels = props.pixels ? props.pixels : 10;

    let transform = 'translate3d(0, 0, 0)';
    if (to === 'top') {
      transform = `translate3d(0, ${pixels}px, 0)`;
    } else if (to === 'bottom') {
      transform = `translate3d(0, ${-Math.abs(pixels)}px, 0)`;
    } else if (to === 'left') {
      transform = `translate3d(${pixels}px, 0, 0)`;
    } else if (to === 'right') {
      transform = `translate3d(${-Math.abs(pixels)}px, 0, 0)`;
    }

    let config: {
      opacity: number;
      transform?: string;
      from: { opacity: number; transform?: string };
    } = {
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
      from: { opacity: props.nofade === 'true' ? 1 : 0, transform },
    };
    if (props.notransform) {
      config = { opacity: 1, from: { opacity: 0 } };
    }

    const values = useSpring(config);
    return (
      <animated.div style={values} ref={ref} {...props}>
        {props.children}
      </animated.div>
    );
  }
);
