import cuid from 'cuid';
import format from 'date-fns/format';
import intervalToDuration from 'date-fns/intervalToDuration';
import spacetime from 'spacetime';

// Add a tempID to all objects in an array
export function toTempIDs<T>(params: { value: T[] }): T[] {
  const temp: T[] = [];

  params.value.forEach((item) => {
    const tempItem: any = { ...item };
    tempItem.tempID = cuid();
    temp.push(tempItem);
  });

  return temp;
}

// Using JavaScript's default toFixed function will round decimals
// (1.225).toFixed(2) = 1.23
// (-1.225).toFixed(2) = -1.23
// This function will remove decimals after 2 places
// toFixed({ value: 1.225 }) = 1.22
// toFixed({ value: -1.225 }) = -1.22
export function toFixed(params: { value: number; decimals?: number }) {
  const decimals = params.decimals || 2;
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (decimals || -1) + '})?');
  const value = params.value.toString().match(re);
  if (value !== null) {
    return Number.parseFloat(value[0]);
  } else {
    return params.value;
  }
}

export function toCurrency(params: {
  value: number | null;
  displayAllDigits?: boolean;
}) {
  if (params.value !== null) {
    if (params.displayAllDigits) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 4,
        maximumFractionDigits: 8,
      });
      return formatter.format(params.value);
    } else {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      const fixedValue = toFixed({ value: params.value });
      return formatter.format(fixedValue);
    }
  } else {
    return null;
  }
}

export function toPhoneNumber(params: { value: string | number }): string {
  // Filter only numbers from the input
  const cleaned = ('' + params.value).replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]})${match[2]}-${match[3]}`;
  } else {
    return params.value.toString();
  }
}

export function toDateFormat(params: { value: Date | string | null }) {
  if (params.value === '0001-01-01T00:00:00.0000000') {
    return null;
  } else if (params.value !== null) {
    return format(new Date(params.value), 'yyyy-MM-dd');
  } else {
    return null;
  }
}

export function toAgeInYears(params: { value: Date | string | null }) {
  if (params.value) {
    const ageDifMs = Date.now() - new Date(params.value).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  } else {
    return null;
  }
}

export const toAgeInDuration = (params: { start: Date; end: Date }): string => {
  const { start, end } = params;
  const duration = intervalToDuration({ start, end });

  let timeAgo = '';
  // Years
  if (duration.years !== undefined && duration.years > 0) {
    timeAgo += `${duration.years} year${duration.years > 1 ? 's' : ''}`;
  }
  // Months
  if (duration.months !== undefined && duration.months > 0) {
    timeAgo += ` ${duration.months} month${duration.months > 1 ? 's' : ''}`;
  }
  // Days
  if (duration.days !== undefined && duration.days > 0) {
    timeAgo += ` ${duration.days} day${duration.days > 1 ? 's' : ''}`;
  }

  return timeAgo;
};

// A lot of our data has dates formatted like this "2014-08-25 00:00:00"
// In Safari new Date("2014-08-25 00:00:00") is an invalid date
// This will replace a specific list of dates with valid dates
// All dates formatted like "2014-08-25 00:00:00" are assumed to be EST
// This function will take a date like "2020-12-15 15:53:45.847"
// and return "Tue Dec 15 2020 15:53:45 GMT-0500 (EST)" for users in EST
// and return "Tue Dec 15 2020 12:53:45 GMT-0800 (PST)" for users in PST
export function toSafeDate(params: { value: string }) {
  if (typeof params.value === 'string' && params.value.includes(' ')) {
    return spacetime(params.value, 'America/New_York').toLocalDate();
  } else {
    return params.value;
  }
}

// This function will take an array of data and check specific keys for formatting
export function toSafeDates<T>(params: { value: T[] }) {
  const items: T[] = [];

  params.value.forEach((value) => {
    const item: any = { ...value };

    Object.keys(item).forEach((i) => {
      const hasValue = item[i] !== undefined && item[i] !== null;
      const shouldFormat =
        i === 'DateOfBirth' ||
        i === 'Created' ||
        i === 'Modified' ||
        i === 'DistDate' ||
        i === 'Expire' ||
        i === 'Issued' ||
        i === 'IssuedDate' ||
        i === 'DateEntered' ||
        i === 'DateCreated' ||
        i === 'DateAccepted' ||
        i === 'DateAdded' ||
        i === 'AprvdDate' ||
        i === 'EventStart' ||
        i === 'date_created' ||
        i === 'record_created' ||
        i === 'record_updated' ||
        i === 'activated' ||
        i === 'sig_stamp' ||
        i === 'wol_stamp' ||
        i === 'stamp' ||
        i === 'lastaccesstime' ||
        i === 'override_time' ||
        i === 'TxDate' ||
        i === 'ChgDate';
      if (hasValue && shouldFormat) {
        if (i === 'DateOfBirth' && item[i].includes(' ')) {
          // "2000-01-31 00:00:00".split(' ')[0] = "2000-01-31"
          item[i] = item[i].split(' ')[0];
        } else if (i === 'DateOfBirth' && item[i].includes('T')) {
          // "2000-01-31T00:00:00".split('T')[0] = "2000-01-31"
          item[i] = item[i].split('T')[0];
        } else {
          item[i] = toSafeDate({ value: item[i] });
        }
      }
    });

    items.push(item);
  });

  return items;
}

export function toPercentage(params: {
  value: number | null;
  removeDigits?: boolean;
}) {
  if (params.value !== null) {
    return Number(params.value).toLocaleString(undefined, {
      style: 'percent',
      minimumFractionDigits: params.removeDigits ? 0 : 2,
    });
  } else {
    return null;
  }
}

/**
 * Use this when the specific time of day is not relevant
 *
 * This can cause issues:
 * new Date("2024-02-07") > Tue Feb 06 2024 19:00:00 GMT-0500 (Eastern Standard Time)
 * new Date("2024-02-07T20:34:36.957Z") > Wed Feb 07 2024 15:34:36 GMT-0500 (Eastern Standard Time)
 *
 * This is what we want:
 * new Date("2024-02-07T00:00") > Wed Feb 07 2024 00:00:00 GMT-0500 (Eastern Standard Time)
 *
 */
export function formatDate(params: { value: string }): string {
  // Regular expression to match the date part in the format yyyy-MM-dd
  const dateRegex = /^(\d{4}-\d{2}-\d{2})/;
  const match = params.value.match(dateRegex);

  if (match) {
    // If a match is found, append 'T00:00' to the matched date portion
    return `${match[1]}T00:00`;
  } else {
    return params.value;
  }
}
